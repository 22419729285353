import React from "react";
import Head from "next/head";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import * as Sentry from "@sentry/node";
import { QueryClientProvider, QueryClient } from "react-query";
import { Hydrate } from "react-query/hydration";
import "global.css";
import { createLoopTheme } from "@loop-brasil/material";
import { ReactQueryDevtools } from "react-query/devtools";
import { SessionProvider } from "next-auth/react";
import dynamic from "next/dynamic";

const DynamicPolicyCookies = dynamic(() => import("components/PolicyCookies"), {
  ssr: false
});

const DynamicReferralContact = dynamic(
  () => import("components/User/ReferralContact"),
  { ssr: false }
);

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENV
  });
}

const theme = createLoopTheme();

const HALF_HOUR = 60 * 30;

const LoopCarros = ({
  Component,
  pageProps: { session, ...pageProps },
  err
}) => {
  const queryClientRef = React.useRef();
  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: false
        }
      }
    });
  }

  return (
    <QueryClientProvider client={queryClientRef.current}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Head>
        <meta charSet="utf-8" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Loop Veículos" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="loopveiculos.com.br" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SessionProvider
          refetchOnWindowFocus={false}
          refetchInterval={HALF_HOUR}
          session={session}
        >
          <Hydrate state={pageProps.dehydratedState}>
            <Component {...pageProps} err={err} />
          </Hydrate>
          <DynamicPolicyCookies />
          <DynamicReferralContact />
        </SessionProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default LoopCarros;
